import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface Message {
  id?: number;
}

@Module
export default class MessageModule extends VuexModule {
  message = {} as Message;

  get getCurrentMessage(): Message {
    return this.message;
  }

  @Mutation
  [Mutations.SET_MESSAGE](id) {
    this.message.id = id;
  }

  @Mutation
  [Mutations.RESET_MESSAGE]() {
    this.message = {};
  }
}
