import lscache from "lscache";
const ID_TOKEN_KEY = "token" as string;
const ID_CPUUID_KEY = "CP_UUID" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return lscache.get(ID_TOKEN_KEY);
};

export const getCpUUID = (): string | null => {
  return lscache.get(ID_CPUUID_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  lscache.set(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  lscache.remove(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
